
import {Component, Vue} from "vue-property-decorator";
import {ILoginParam} from "@/apis/core/types";
import {Message} from "element-ui";
import config from "@/config";

@Component({})
export default class LoginPage extends Vue {
  config = config;
  // 登录表单
  form: ILoginParam = {
    account: "",
    pwd: ""
  }

  /**
   * 登录
   */
  handleLogin() {
    if (!this.form.account || !this.form.pwd) {
      return Message.error("请输入账号密码")
    }
    this.$store.dispatch("useUserStore/login", this.form).then(e => {
      this.$router.replace("/home/index")
    })
  }
}
